import { useMediaQuery } from '@sortlist-frontend/design-system';
import { Trans } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import { FC, useEffect, useMemo, useState } from 'react';

import { getCompanyLogos } from '_core/utils/company-logos';
import { clientCompaniesData, CompanyType } from '_data/index';
import * as S from '_features/home/sections/Companies/styles';

type Props = {
  invert?: boolean;
};

export const Companies: FC<Props> = (props) => {
  const { invert = true } = props;
  const { media } = useMediaQuery();
  const [logos, setLogos] = useState<CompanyType[] | undefined>(undefined);

  useEffect(() => {
    setLogos(getCompanyLogos(clientCompaniesData, 8));
  }, []);

  return (
    <S.LogosWrapper className="mt-64 mt-gt-sm-0">
      <div className="layout-row layout-align-center-center">
        <S.LogosInnerWrapper
          className={clsx(
            { 'rounded-xl': media.gtSm },
            !invert ? 'bg-secondary-900' : 'border bg-secondary-200 border-secondary-200',
            'p-16 flex-sm-100 flex-sm-100 flex-gt-sm-none',
          )}>
          {invert ? (
            <p className="py-8 text-center text-secondary-700">
              <Trans i18nKey={'home:sections.logos.title_V3'}>
                Sortlist is trusted by <b>+100K businesses</b> worldwide
              </Trans>
            </p>
          ) : null}
          <div className="layout-row layout-align-center-stretch layout-wrap text-secondary-500">
            {logos?.map((company: CompanyType) => (
              <div
                className="layout-row layout-align-center-center"
                key={company.id}
                style={{ minWidth: 112, minHeight: 64 }}>
                <S.Logo
                  className="m-16"
                  alt={company.name}
                  src={company.logoSrc as string}
                  width="80"
                  height="32"
                  key={company.id}
                  invert={invert ? 'true' : 'false'}
                />
              </div>
            ))}
          </div>
        </S.LogosInnerWrapper>
      </div>
    </S.LogosWrapper>
  );
};
