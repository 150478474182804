import { useMediaQuery } from '@sortlist-frontend/design-system';
import clsx from 'clsx';
import { FC, Fragment, useMemo, useState } from 'react';

import { PublicApiRepo, useGetLinks } from '_core/repos/public-api.repo';
import { categoryFiltered } from '_core/utils/public-links/category-filtered';
import { createLevels } from '_core/utils/public-links/createLevels';
import { getLocalizedUrl } from '_core/utils/public-links/getLocalizedUrl';
import { services } from '_core/utils/public-links/services';
import { sortLevels } from '_core/utils/public-links/sort-levels';

import { LinkList } from './components/LinkList';
import * as S from './styles';
import { createSortedLvlAfterPosition } from './utils';

type TopLinksProps = {
  linksIdentificationKey: string;
  seeMoreLink: boolean;
};

export const TopLinks: FC<TopLinksProps> = (props) => {
  const { linksIdentificationKey, seeMoreLink } = props;
  const [isActiveIndex, setIsActiveIndex] = useState(0);
  const { data: links } = useGetLinks(PublicApiRepo.publicApiUrl);
  const { media } = useMediaQuery();

  const allLinks = useMemo(
    () => createLevels(links, services(links, linksIdentificationKey, categoryFiltered), sortLevels),
    [links],
  );

  const sortedLinks = allLinks != null ? createSortedLvlAfterPosition(allLinks) : null;
  const firstLink = useMemo(() => (sortedLinks != null ? Object.keys(sortedLinks[0])[0] : ''), [sortedLinks]);

  const [selectedLink, setSelectedLink] = useState(firstLink);

  if (allLinks == null) return <></>;

  return (
    <section className="py-64 container-lg px-0">
      <div className="layout-column layout-gt-sm-row layout-align-start-stretch">
        <S.CategoriesWrapper
          className={clsx(
            { 'overflow-y-scroll': !media.gtSm },
            'flex-100 flex-gt-sm-33 layout-row layout-gt-sm-column layout-align-start-start mb-24 mb-gt-sm-0 px-16',
          )}>
          {sortedLinks
            ?.sort((a, b) => a.position - b.position) // firefox is crazy, messing up arrays order => hydration issues
            .map((levelTwo, index) => {
              const link = Object.keys(levelTwo)[0];
              return (
                <S.CustomButton
                  key={`${link}-${index}`}
                  className={clsx(
                    { 'ml-16 ml-gt-sm-0': index > 0 },
                    media.gtSm ? 'text-break-word' : 'flex-none',
                    'cursor-pointer text-left bold p-0 ',
                  )}
                  isActive={isActiveIndex === index}
                  data-testid={`skill-category-${index}`}
                  onClick={() => {
                    setIsActiveIndex(index);
                    setSelectedLink(link);
                  }}>
                  {link}
                </S.CustomButton>
              );
            })}
        </S.CategoriesWrapper>
        <div className="layout-column layout-gt-xs-row flex layout-align-start-stretch flex-xs-66">
          {/** Hidden links for the google bot (only links not selected) */}
          {Object.keys(allLinks).map((link, key) => {
            const currentLinks = allLinks[link]['undefined']
              ? allLinks[link]['undefined']['undefined']
              : allLinks[link]['null']['null'];
            if (link !== selectedLink) {
              return (
                <Fragment key={`link-${key}-hidden`}>
                  {currentLinks
                    .sort((a, b) => a.position - b.position) // firefox is crazy, messing up arrays order => hydration issues
                    .map((val, key) => {
                      const localizedLink = getLocalizedUrl(val);
                      return (
                        <S.Anchor
                          href={localizedLink}
                          key={`${val.anchor}-link-${key}-hidden`}
                          className="text-secondary-900 small bold hide">
                          {val.anchor}
                        </S.Anchor>
                      );
                    })}
                </Fragment>
              );
            }
          })}
          <LinkList seeMore={seeMoreLink} selectedLink={selectedLink} allLinks={allLinks} />
        </div>
      </div>
    </section>
  );
};
