import { RefObject, useEffect, useState } from 'react';

export const useIsOverflowing = <T extends HTMLElement>(ref?: RefObject<T>) => {
  const [overflowing, setOverflowing] = useState<boolean | null>(null);

  useEffect(() => {
    const { current } = ref || {};
    // sometimes the bounding box is a bit less than the current element height e.g current: 19.5(curr) vs 20(bb)
    current && setOverflowing(current.scrollHeight > current.getBoundingClientRect().height + 1);
  }, [ref]);

  return overflowing;
};
