import { Monitor } from '@sortlist-frontend/mlm';
import { getStorage } from '@sortlist-frontend/utils';
import { useMutation } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { api } from '_core/api/api-nextjs-proxy';
import { usePublicAppContext } from '_core/context/public-app-context';

type CpcEventName = 'competitorCardClicked' | 'homeCardClicked' | 'agencyCardClicked' | 'searchCardClicked';
export type TrackingParamsCpc = {
  name: CpcEventName;
  url: string; // with query params if any
  agency_external_id: string; // the uuid of the agency that is on a card that got clicked
  position: number; // the position in the grid from 0 -> 10 on a page from top left to bottom right -> needs index from the array iteration
  result_type?: string;
  cta: string;
};

type LoadedEventName = 'longtailLoaded' | 'homeLoaded' | 'searchLoaded';
type TrackingParamsLoaded = {
  name: LoadedEventName;
  url: string; // with query params if any
};

type BriefingEventName = 'briefingStarted' | 'briefingCompleted';
type TrackingParamsBriefing = {
  name: BriefingEventName;
  url: string; // with query params if any
  project_external_id: string; // the uuid of the project
  briefing_type: 'light' | 'compare' | 'direct' | 'referral';
  cta?: string;
};

// Depending on the use case, some params are mandatory or not.
export type Track = TrackingParamsCpc | TrackingParamsLoaded | TrackingParamsBriefing;

type ExtraTrackProps = {
  user_id: string;
  user_type?: string;
  sent_at: string;
  app: string;
};

export const urls = {
  track: `/api/proxy/log`,
};

const getSafeJson = (json: string | false | null) => {
  if (!json) return json;

  try {
    return JSON.parse(json);
  } catch {
    return json;
  }
};

export const trackRepo = {
  track: async (data: Track, config?: AxiosRequestConfig): Promise<any> => {
    const anonymousId = getSafeJson(getStorage('ajs_anonymous_id'));
    const loggedUserId = getSafeJson(getStorage('ajs_user_id'));
    const timestamp = new Date().toISOString();

    const extraTrackProps: ExtraTrackProps = {
      user_id: loggedUserId || anonymousId || '',
      user_type: loggedUserId ? 'logged' : anonymousId ? 'anonymous' : undefined,
      sent_at: timestamp,
      app: 'appPublic',
    };

    const trackResponse = await api.post(
      urls.track,
      {
        data: { attributes: { ...data, ...extraTrackProps } },
      },
      config,
    );

    return trackResponse?.data;
  },
};

export function useTrackWithoutContext(originUrl: string) {
  return { mutateAsync: (data: Track) => trackRepo.track(data, { baseURL: originUrl }) };
}

export function useTrack() {
  const { domainInfo } = usePublicAppContext();
  const baseURL = domainInfo?.getOriginUrl() ?? '';

  return useMutation({
    mutationFn: (data: Track) => trackRepo.track(data, { baseURL }),
    onError: (error, variables) => {
      const responseHeaders = (error as any)?.props?.previousError?.response?.headers;
      const previousError = (error as any)?.props?.previousError;

      const errorMsg = error.message;
      // ignore errors that are not related to the network
      if (
        errorMsg &&
        (errorMsg.match(/Network Error/i) ||
          errorMsg.match(/Fetch Error/i) ||
          errorMsg.match(/Request aborted/i) ||
          errorMsg.match(/XHR Error/i) ||
          errorMsg.match(/adsbygoogle/i) ||
          errorMsg.match(/Failed to fetch/i))
      ) {
        return null;
      }

      Monitor.captureMessage('useTrack error', {
        extra: { variables, error, responseHeaders, previousError },
      });
    },
  });
}
