import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS } from '@sortlist-frontend/design-system';

export const Anchor = styled.a`
  &:hover {
    color: ${COLORS.PRIMARY_500};
  }
`;

export const ColumnUl = styled.ul<{ isXs?: boolean }>`
  ${({ isXs }) => `
    columns: ${isXs ? 1 : 2};
  `}
`;

export const CustomButton = styled.button<{ isActive?: boolean }>`
  ${({ isActive }) => `
    background-color: transparent;
    border: none;
    font-size: 1.375rem;
    line-height: 2rem;
    transition: all 0.3s ease-in-out;
    color: ${isActive ? COLORS.SECONDARY_900 : COLORS.SECONDARY_300};

    @media only screen and (min-width: ${BREAKPOINTS.xs}) {
      font-size: 2.375rem;
      line-height: 2.85rem;
    }

    &:hover {
      transition: all 0.3s ease-in-out;
      color: ${isActive ? COLORS.SECONDARY_900 : COLORS.SECONDARY_500};
    }
  `}
`;

export const CategoriesWrapper = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Title = styled.span<{ isHeroCard?: boolean }>`
  ${({ isHeroCard }) => `
    font-size: ${isHeroCard ? '4.5rem' : '1.5rem'};
  `}
`;
