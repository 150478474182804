import { useMediaQuery } from '@sortlist-frontend/design-system';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { FC } from 'react';

import { usePublicAppContext } from '_core/context/public-app-context';
import { Levels } from '_core/utils/public-links/_types';
import { getLocalizedUrl } from '_core/utils/public-links/getLocalizedUrl';
import { homeConfig } from '_features/home/home.config';

import * as S from '../styles';
import { createSortedLvlAfterPosition } from '../utils';

type LinkListProps = {
  selectedLink?: string;
  allLinks: Levels;
  seeMore: boolean;
};

export const LinkList: FC<LinkListProps> = ({ selectedLink, allLinks, seeMore }) => {
  const { t } = useTranslation(homeConfig.i18nNamespaces);
  const { media } = useMediaQuery();

  /* The selectedAnchors test below is necessary as long as we have two types of payload returned by Olivier for the links. Once we only have the good one, use only the allLinks[selectedLink]['undefined']['undefined'] identifier */
  const firstLink = Object.keys(createSortedLvlAfterPosition(allLinks)[0])[0];
  const selectedAnchors = allLinks[selectedLink || firstLink]['undefined']
    ? allLinks[selectedLink || firstLink]['undefined']['undefined']
    : allLinks[selectedLink || firstLink]['null']['null'] || [];
  const { getUrl } = usePublicAppContext();

  return (
    <>
      {selectedAnchors.length > 0 && (
        <S.ColumnUl isXs={media.xs} className="list-reset">
          {selectedAnchors
            .sort((a, b) => a.position - b.position) // firefox is crazy, messing up arrays order => hydration issues
            .map((val, index: number) => {
              const localizedLink = getLocalizedUrl(val);
              return (
                <li
                  className="px-32 py-xs-8 py-4"
                  key={`${val.anchor}-${index}`}
                  data-testid={`skill-link-${val.link.replace(/\//g, '')}`}>
                  <S.Anchor href={localizedLink} className="text-secondary-900 small">
                    {val.anchor}
                  </S.Anchor>
                </li>
              );
            })}
          {seeMore && (
            <li key="see-more-skills" className="px-32 py-xs-8 py-4">
              <S.Anchor href={getUrl('client.explore')} className="small text-secondary-900 text-underline">
                {t('home:sections.skills.seeMore')}
              </S.Anchor>
            </li>
          )}
        </S.ColumnUl>
      )}
    </>
  );
};
